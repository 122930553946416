<div #matMenuTrigger [matMenuTriggerFor]="nearbyEventsRangeMenu" (menuOpened)="rangeOpened()"
     matTooltip="Set range"
     class="cursor-pointer flex gap-2">
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
       height="20px" viewBox="0,0,256,256">
    <g fill="#41abf3" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter"
       stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none"
       font-size="none" text-anchor="none" style="mix-blend-mode: normal">
      <g transform="scale(10.66667,10.66667)">
        <path
          d="M7,3c-1.09306,0 -2,0.90694 -2,2v5h-4v2h4v5.53906c0,0.58424 0.25667,1.13879 0.69727,1.51758v0.00195l3.80273,3.25781l3.80273,-3.25781v-0.00195c0.44059,-0.37874 0.69727,-0.93334 0.69727,-1.51758v-12.53906c0,-1.09306 -0.90694,-2 -2,-2zM7,5h5v12.53906l-2.5,2.14453l-2.5,-2.14453zM16,10v2h7v-2z">
        </path>
      </g>
    </g>
  </svg>
  <span class="text-sm text-[#41abf3]">SET RANGE</span>
</div>

<mat-menu #nearbyEventsRangeMenu="matMenu" class="filter-range ig-filter-range-tx w-[400px] !max-w-[400px] !px-4 !py-1"
          igClickStopPropagation>
  <div>Set range (in miles):</div>
  <div class="flex gap-4" igClickStopPropagation>
    <ngx-slider [(value)]="range" [options]="rangeOptions" igClickStopPropagation
                (userChange)="rangeEnd($event)"></ngx-slider>
    <!-- <ig-button [style]="'flat'" cls="mt-4 rounded-sm h-8" (trigger)="setRange()">OK</ig-button> -->
    <div
      class="p-2 bg-[#41abf3] text-white flex justify-center items-center h-8 mt-3 cursor-pointer transition-all rounded-sm hover:shadow-md"
      (click)="setRange()">OK
    </div>
  </div>
</mat-menu>
