import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Options } from 'ngx-slider-v2';

@Component({
  selector: 'ig-set-range',
  templateUrl: './set.range.component.html',
  styleUrls: ['./set.range.component.scss'],
})
export class SetRangeComponent {
  @Input() range: number = 2;

  rangeOptions: Options = {
    floor: 1,
    ceil: 10,
    step: 1,
    showTicks: true,
    showTicksValues: true,
  };

  @ViewChild(MatMenuTrigger, {static: false})
  matMenuTrigger: MatMenuTrigger;
  @Output() rangeSet: EventEmitter<number> = new EventEmitter<number>();

  setRange() {
    this.matMenuTrigger?.closeMenu();
    this.rangeSet.emit(this.range);
  }

  rangeEnd($event) {
    setTimeout(() => {
      const pointerTx = document.querySelector(
        '.filter-range.ig-filter-range-tx'
      );
      (pointerTx as HTMLElement).classList.remove('ig-filter-range-tx');
    });
  }

  public rangeOpened() {
    // slider component on popup has some styling issues, here is to adjust the style
    setTimeout(() => {
      // set range track position
      const points = document.querySelectorAll(
        '.filter-range ngx-slider .ngx-slider-ticks .ngx-slider-tick'
      );

      points.forEach((p, i) => {
        // (p as HTMLElement).style.transform = `translateX(${i * 28.5}px)`;
        (p as HTMLElement).style.transform = `translateX(${i * 31.55}px)`;
      });

      setTimeout(() => {
        // set draggable pointer position
        const pointer = document.querySelector(
          '.filter-range .ngx-slider-pointer'
        );
        const slider = document.querySelector('.filter-range ngx-slider');
        const sliderRect = (slider as HTMLElement).getBoundingClientRect();

        const pointRect = (
          points[this.range - 1] as HTMLElement
        ).getBoundingClientRect();

        (pointer as HTMLElement).style.left = `${
          pointRect.left - sliderRect.left + (this.range >= 4 ? 2 : 0)
        }px`;
      });
    }, 100);
  }
}
